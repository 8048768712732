.el-button + .el-button[data-v-36558bfc] {
  margin-left: 0px;
}
[data-v-36558bfc] .avue-crud .el-table {
  height: calc(100vh - 218px) !important;
  max-height: calc(100vh - 218px) !important;
}
.paging[data-v-36558bfc] {
  float: right;
  margin-top: 10px;
}
.show_table[data-v-36558bfc] {
  position: relative;
  width: 100%;
  height: 580px;
  overflow: auto;
}